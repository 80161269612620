@import 'mixins/media';

.slide-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: 100vh;
  gap: 60px;
  z-index: 100;
  @include full{
    gap: 80px;
  }

}

.slide-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
}

.slide-inner-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 60px;
  height: 100%;
  &--start {
    justify-content: flex-start;
  }
}
.slide-inner-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 80px;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}


.slide-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &--row {
    flex-direction: column;
  }
}
.flex-start {
  justify-content: flex-start;
}

.flex-center {
  justify-content: center;
}


.flex-end {
  justify-content: flex-end;
}
