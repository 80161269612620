@import 'variables/colors';
@import 'mixins/media';

.slide {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  &-inner {
    padding: 0 50px 0 80px;
    width: 100%;
    box-sizing: border-box;
    @include media('>full') {
      padding: 0 50px 0 140px;
    }
  }
}

.slides {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $black-light;
}

.element {
  background-color: $gray;
  border-radius: 25px;
  z-index: -1;
  opacity: 0.5;
  &--top {
    width: 500px;
    position: absolute;
    right: -80px;
    top: -80px;
    height: 400px;
  }
  &--bottom {
    width: 500px;
    position: absolute;
    left: 400px;
    bottom: -80px;
    height:200px;
  }
}
