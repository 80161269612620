@import 'variables/colors';
@import 'mixins/media';

.center {
  text-align: center;
}

.margin-b-md {
  margin-bottom: 60px;
}

.primary {
  color: $primary;
}
.badge {
  padding: 20px 60px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  &--success {
    background-color: $green;
    color: #fff;
  }

  &--error {
    background-color: $red;
    color: #fff;
  }
}

.badge-container {
  text-align: left;
  align-self: flex-start;
  margin-bottom: 50px;
}
.slide-label {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 50px;
  @include full {
   font-size: 25px;
    margin-bottom: 100px;
  }
}

.slide-caption {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
  opacity: 0.7;
  @include media('>full') {
    font-size: 40px;
    margin-bottom: 80px;
  }
}

.black-top {
  background-image: url("/assets/intro-black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(200deg);
  top: -150px;
  right: -300px;
  width: 800px;
  height: 500px;
  z-index: 0;
  position: fixed;
  @include full {
    top: -200px;
    right: -350px;
    width: 800px;
    height: 700px;
    transform: rotate(230deg);
  }
}

.black-bottom {
  background-image: url("/assets/intro-black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(0deg);
  bottom: -250px;
  left: -100px;
  width: 800px;
  height: 500px;
  z-index: 0;
  position: fixed;
}


.image-caption {
  font-size: 14px;
  &:before {
    content: " ";
    width: 14px;
    height: 14px;
    display: inline-block;
    background-color: $blue;
    border-radius: 100%;
    margin-right: 10px;
  }
}
