@import 'mixins/media';

.title {
  color: $black;
  font-weight: 900;
  margin-bottom: 40px;
  margin-top: 0;
  font-size: 65px;
  white-space: break-spaces;

  @include full{
    font-size: 100px;
  }


  &--md {
    font-size: 50px!important;
    @include full{
    font-size: 80px;
    }
  }

  &--sm {
    font-size: 35px!important;
    @include full{
      font-size: 60px!important;
    }
    @media screen and (max-height: 800px) {
      font-size: 50px!important;
    }
  }

  &--xs {
    font-size: 45px!important;
    margin-bottom: 10px;
  }
}

.subtitle {
  font-size: 20px;
  margin-bottom: 30px;
  &--sm {
    font-size: 20px!important;

  }
  @include full{
    font-size: 35px;
    margin-bottom: 50px;
  }

  @media screen and (max-height: 800px) {
    font-size: 20px!important;
  }
}


.text-white {
  color: #fff;
}
