@import "variables/colors";
@import "mixins/media";
.box-list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 235px);
  gap: 20px;
  width: 100%;
  padding: 0;
  &--center {
    justify-content: center;
  }

}

.box {
  background: #fff;
  box-shadow: 0 30px 60px #0000001A;
  border-radius: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  box-sizing: border-box;
  @include full {
    padding: 20px;
  }
}
