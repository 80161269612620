@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');
@import '~normalize.css';
@import './scss/variables/colors';

@import './scss/utils/grid';
@import './scss/utils/typography';
@import './scss/utils/helpers';

/*
Components
*/
@import './scss/components/slide';
@import './scss/components/box';

body {
  color:$black;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: #03A7FB white;   /* scroll thumb and track */
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color:  #03A7FB;
  border-radius: 20px;

}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
